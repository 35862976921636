import { rfiHelperFormApi } from 'api'
import { AgencyAddedResponse } from 'types/agencies/agencies'

/**
 * pass formData file and params to add an agency
 * @param params - name and description of the agency
 * @param formData - file to upload
 * */

export interface AddAgencyParams {
  params: {
    // file_keys: string[]
    name: string
    description: string
  }
  agencyId?: string
  formData?: FormData
}

export const addAgency = (data: AddAgencyParams) => {
  const entries = Object.entries(data.params)
    .flatMap(([key, value]) =>
      Array.isArray(value)
        ? value.map(v => `${encodeURIComponent(key)}=${encodeURIComponent(v)}`) // Handle array values
        : `${encodeURIComponent(key)}=${encodeURIComponent(value)}`,
    )
    .join('&')

  if (data?.formData) {
    return rfiHelperFormApi.post<AgencyAddedResponse>(`/agencies_v2?${entries}`, data.formData)
  }

  return rfiHelperFormApi.post<AgencyAddedResponse>(`/agencies_v2?${entries}`)
}
