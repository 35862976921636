import {
  WppButton,
  WppDivider,
  WppInput,
  WppTypography,
  WppSelect,
  WppListItem,
  WppCheckbox,
  WppTextareaInput,
} from '@wppopen/components-library-react'
import clsx from 'clsx'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { AddUseCaseParams } from 'api/fetchers/use-cases/createUseCase'
import { ReplaceUseCaseFileParams } from 'api/fetchers/use-cases/replaceUseCaseFile'
import { useCreateUseCase } from 'api/mutations/use-cases/useCreateUseCase'
import { useReplaceUseCaseFile } from 'api/mutations/use-cases/useReplaceUseCaseFile'
import { useUpdateUseCase } from 'api/mutations/use-cases/useUpdateUseCase'
import { useGetAgencies } from 'api/queries/agencies/useGetAgencies'
import { useMarkets } from 'api/queries/markets/useMarkets'
import { useRegions } from 'api/queries/markets/useRegions'
import { usePitchTypes } from 'api/queries/pitch-types/usePitchTypes'
import { useTasksStatus } from 'api/queries/task-status/useTasksStatus'
import { queryClient } from 'app/Root'
import { ProgressApiRes } from 'components/LoaderProgressWithDescription'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { useToast } from 'hooks/useToast'
// import AgencyFileList from './agencyFileList/AgencyFileList'
import { MarketType } from 'types/markets/market'
import { UseCase } from 'types/use-cases/useCase'

import { UseCaseEditAddFile } from './UseCaseEditAddFile'
import style from './UseCasesEditAdd.module.scss'

export interface Props {
  useCase?: UseCase
  handleCancel?: () => void
  handleSave?: (name?: string, description?: string) => void
  showLlmSummary?: boolean
}

export const UseCasesEditAdd = ({ useCase, handleCancel, handleSave, showLlmSummary }: Props) => {
  const { mutateAsync: updateUseCase } = useUpdateUseCase()
  const { mutateAsync: updateUseCaseFile } = useReplaceUseCaseFile()

  const { data: marketsData = [] } = useMarkets()
  const { data: agencies = [] } = useGetAgencies()
  const { data: regions = [] } = useRegions()
  const { data: pitchTypes = [] } = usePitchTypes()
  const markets = useMemo(() => [...regions, ...marketsData] as MarketType[], [marketsData, regions])
  const subCategories = useMemo(
    () => [
      'Context',
      'Client RFP and Briefs',
      'Pitch and RFP Responses',
      'Commercials',
      'Case Studies',
      'Competitor Intel',
    ],
    [],
  )

  const [nameValue, setNameValue] = useState<string>(useCase?.title || '')
  const [descriptionValue, setDescriptionValue] = useState<string>(useCase?.description || '')
  const [clientNamesSelectValue, setClientNamesSelectValue] = useState<string>(useCase?.client || '')
  const [agencieSelectValues, setAgencieSelectValues] = useState<string[]>(useCase?.agencyIds || [])
  const [pitchTypeSelectValues, setPitchTypeSelectValues] = useState<string[]>(useCase?.pitchTypes || [])
  const [marketsSelectValues, setMarketsSelectValues] = useState<string[]>(useCase?.markets || [])
  const [subCategoriesSelectValues, setSubCategoriesSelectValues] = useState(useCase?.subCategory || '')
  const [isConfidentialValue, setIsConfidentialValue] = useState(useCase?.isConfidential ?? true)
  const [wppFiles, setWppFiles] = useState<File[] | null>(null)
  const descriptionAreaRef = useRef<HTMLWppTextareaInputElement>(null)
  const existingFileUrl = useCase?.fileUrl || null

  const [disableSave, setDisableSave] = useState(!useCase?.title)
  const [task, setTask] = useState<ProgressApiRes | null>(null)
  const toast = useToast()
  const { data: taskStatus } = useTasksStatus({
    params: { taskId: task?.id || '' },
    enabled: !!task?.id,
    refetchInterval: wppFiles?.length ? 5000 : 2000,
  })
  const isEditMode = typeof useCase !== 'undefined'

  const maxLength = 1000
  const { mutateAsync: addUseCase } = useCreateUseCase({
    onError: error => {
      toast.showToast({
        message: error.message,
        type: 'error',
      })
    },
  })

  const prepareRequest = useCallback((): AddUseCaseParams => {
    let request: AddUseCaseParams
    if (useCase?.id) {
      request = {
        params: {
          id: useCase?.id,
          title: nameValue,
          markets: marketsSelectValues,
          client: clientNamesSelectValue,
          agencyIds: agencieSelectValues,
          pitch_types: pitchTypeSelectValues,
          sub_category: subCategoriesSelectValues,
          isConfidential: isConfidentialValue,
          description: descriptionValue,
        },
      }
    } else {
      request = {
        params: {
          title: nameValue,
          markets: marketsSelectValues,
          client: clientNamesSelectValue,
          agencyIds: agencieSelectValues,
          pitch_types: pitchTypeSelectValues,
          sub_category: subCategoriesSelectValues,
          isConfidential: isConfidentialValue,
          description: descriptionValue,
        },
      }
    }

    if (wppFiles && !useCase) {
      const formData = new FormData()
      /* generate the files blob */
      const files = wppFiles.map(file => new Blob([file], { type: 'application/pdf' }))
      const file = files[0]
      formData.append('file', file, wppFiles[0]?.name)
      request = {
        formData,
        params: {
          title: nameValue,
          markets: marketsSelectValues,
          client: clientNamesSelectValue,
          agencyIds: agencieSelectValues,
          pitch_types: pitchTypeSelectValues,
          sub_category: subCategoriesSelectValues,
          isConfidential: isConfidentialValue,
          description: descriptionValue,
        },
      }
    }

    /*  */
    return request
  }, [
    agencieSelectValues,
    clientNamesSelectValue,
    marketsSelectValues,
    nameValue,
    pitchTypeSelectValues,
    subCategoriesSelectValues,
    useCase,
    wppFiles,
    descriptionValue,
    isConfidentialValue,
  ])

  const prepareRequestReplaceFile = useCallback((): ReplaceUseCaseFileParams | null => {
    let request: ReplaceUseCaseFileParams

    if (wppFiles) {
      const formData = new FormData()
      /* generate the files blob */
      const files = wppFiles.map(file => new Blob([file], { type: 'application/pdf' }))
      const file = files[0]
      formData.append('file', file, wppFiles[0]?.name)
      request = {
        formData,
        useCaseId: useCase?.id || '',
      }
    } else {
      return null
    }

    /*  */
    return request
  }, [useCase, wppFiles])

  const handleFileUploadChange = (event: CustomEvent) => setWppFiles(event.detail.value)
  const handleClearAll = () => {
    setNameValue('')
    setDescriptionValue('')
    setWppFiles(null)
  }

  const disableSaveMemo = useMemo(() => {
    if (
      nameValue &&
      clientNamesSelectValue &&
      agencieSelectValues.length &&
      pitchTypeSelectValues.length &&
      marketsSelectValues.length &&
      !disableSave &&
      !useCase &&
      wppFiles?.length &&
      wppFiles?.length > 0
    ) {
      return false
    } else if (
      nameValue &&
      clientNamesSelectValue &&
      agencieSelectValues.length &&
      pitchTypeSelectValues.length &&
      marketsSelectValues.length &&
      !disableSave &&
      useCase
    ) {
      return false
    } else {
      return true
    }
  }, [
    agencieSelectValues.length,
    clientNamesSelectValue,
    disableSave,
    marketsSelectValues.length,
    nameValue,
    pitchTypeSelectValues.length,
    useCase,
    wppFiles?.length,
  ])
  /*
   * For updating in the background we doing it on blur
   */
  const onHandleEdit = async () => {
    if (isEditMode && typeof useCase?.id !== 'undefined') {
      if (!nameValue) {
        return toast.showToast({
          message: 'Please enter a name for the agency',
          type: 'error',
        })
      }
      try {
        await updateUseCase({
          id: useCase.id,
          title: nameValue,
          markets: marketsSelectValues,
          client: clientNamesSelectValue,
          agencyIds: agencieSelectValues,
          pitch_types: pitchTypeSelectValues,
          sub_category: subCategoriesSelectValues,
          isFavorite: useCase.isFavorite,
          isConfidential: isConfidentialValue,
          description: descriptionValue,
        })
        queryClient.invalidateQueries({ queryKey: [ApiQueryKeys.USE_CASE] })
        toast.showToast({
          message: 'Use Case successfully updated',
          type: 'success',
        })
        // setTask(res.data)
        handleSave && handleSave()
      } catch (e) {
        toast.showToast({
          message: 'Something went wrong while editing the use case. Please try again.',
          type: 'error',
        })
      }
    }
  }

  const handleEditFile = async () => {
    if (isEditMode && typeof useCase?.id !== 'undefined') {
      const request = prepareRequestReplaceFile()

      try {
        if (request === null) {
          toast.showToast({
            message: 'Something went wrong while editing the file. Please try again.',
            type: 'error',
          })
          return
        }
        const res = await updateUseCaseFile(request)
        if (wppFiles) {
          toast.showToast({
            message: 'Parsing files for processing...',
            type: 'information',
            duration: 5000,
          })
          handleSave && handleSave()
        }
        setTask(res.data)
      } catch (e) {
        toast.showToast({
          message: 'Something went wrong while editing the file. Please try again.',
          type: 'error',
        })
      }
    }
  }
  /*
   * error & success handling
   */
  const onHandleSave = useCallback(async () => {
    setDisableSave(true)
    let request = prepareRequest()

    if (!nameValue) {
      return toast.showToast({
        message: 'Please enter a name for the agency',
        type: 'error',
      })
    }

    try {
      const res = await addUseCase(request)
      if (wppFiles) {
        toast.showToast({
          message: 'Parsing files for processing...',
          type: 'information',
          duration: 5000,
        })
      }
      setTask(res.data)
      handleSave && handleSave()

      // handleSave && handleSave(nameValue, descriptionValue)
    } catch (e) {
      toast.showToast({
        message: 'Something went wrong while adding the agency. Please try again.',
        type: 'error',
      })
    }
    setDisableSave(false)
    handleClearAll()
  }, [addUseCase, handleSave, nameValue, prepareRequest, toast, wppFiles])

  const onHandleCancel = () => {
    handleClearAll()
    handleCancel && handleCancel()
  }

  const handleCheckCharsOnPaste = (e: React.ClipboardEvent, value: string) => {
    const { clipboardData } = e
    const text = clipboardData.getData('text')
    if (text.length + value.length > maxLength) {
      e.preventDefault()
      toast.showToast({
        message: `You can't paste more than ${maxLength} characters`,
        type: 'error',
      })
    }
  }

  useEffect(() => {
    if (descriptionAreaRef.current) {
      const shadowRoot = descriptionAreaRef.current.shadowRoot
      const textArea = shadowRoot?.querySelector('textarea')
      textArea?.setAttribute('maxlength', maxLength.toString())
    }
  }, [descriptionValue])

  useEffect(() => {
    const { status, completed } = taskStatus || {}
    if (status === 'Parsing files for processing...') {
      toast.showToast({
        message: 'Parsing files for processing...',
        type: 'information',
        duration: 14000,
      })
    }
    if (status === 'failed') {
      toast.showToast({
        message: `Use case ${useCase ? 'edit' : 'creation'} was not successful`,
        type: 'error',
      })
    }
    if (status?.includes('Error')) {
      toast.showToast({
        message: `Use case ${useCase ? 'edit' : 'creation'} was not successful`,
        type: 'error',
      })
    }

    if (status === 'completed' || completed) {
      queryClient.invalidateQueries({ queryKey: [ApiQueryKeys.USE_CASES, ApiQueryKeys.USE_CASE] }).then(() => {
        toast.showToast({
          message: `${!useCase ? "You've successfully added a new use case" : "You've successfully updated " + useCase?.title + ' use case'}`,
          type: 'success',
        })
      })
      handleSave && handleSave(nameValue, descriptionValue)

      setTask(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [useCase, taskStatus])

  useEffect(() => {
    setDisableSave(!nameValue)
  }, [nameValue])

  return (
    <>
      <div>
        <WppInput
          name={nameValue}
          placeholder="Type use case name"
          required
          value={nameValue}
          onWppChange={e => setNameValue(e?.detail?.value || '')}
          {...(!isEditMode ? { labelConfig: { text: 'Use Case Name' } } : {})}
        />
      </div>
      {/* DESCRIPTION */}
      <div className="mb-4">
        <WppTextareaInput
          ref={descriptionAreaRef}
          labelConfig={{
            text: 'Description',
          }}
          onWppChange={e => setDescriptionValue(e?.detail?.value || '')}
          value={descriptionValue}
          charactersLimit={maxLength}
          maxMessageLength={maxLength}
          warningThreshold={maxLength - 100}
          content={descriptionValue}
          onPaste={e => handleCheckCharsOnPaste(e, descriptionValue)}
          className={style.textArea}
        />
      </div>
      {/* LLM SUMMARY */}
      {showLlmSummary && (
        <div>
          <WppTypography type="s-strong" className="text-grey mb-2">
            LLM Generated Summary:
          </WppTypography>
          <WppTypography type="s-body">{useCase?.summary || ''}</WppTypography>
        </div>
      )}
      {/* CLIENT */}
      <WppInput
        aria-label="Client"
        title="Client"
        placeholder="Client name"
        labelConfig={{
          text: 'Client',
        }}
        value={clientNamesSelectValue}
        required
        onWppChange={e => {
          setClientNamesSelectValue(String(e?.detail?.value || ''))
        }}
      />
      {/* MARKETS */}
      <WppSelect
        aria-label="Markets"
        title="Markets"
        placeholder="Select markets"
        type="multiple"
        labelConfig={{
          text: 'Markets',
        }}
        withSearch
        withFolder
        value={[...marketsSelectValues]}
        required
        onWppChange={e => {
          setMarketsSelectValues(e.detail.value)
        }}
      >
        {markets?.map(item => (
          <WppListItem key={item.id} value={item.name}>
            <p slot="label">{item.name}</p>
          </WppListItem>
        ))}
      </WppSelect>
      {/* PITCH TYPES */}
      <WppSelect
        aria-label="Pitch type"
        title="Pitch type"
        placeholder="Select pitch type"
        type="multiple"
        labelConfig={{
          text: 'Pitch type',
        }}
        withSearch
        value={pitchTypeSelectValues}
        withFolder
        required
        onWppChange={e => {
          setPitchTypeSelectValues(e.detail.value)
        }}
      >
        {pitchTypes?.map(item => (
          <WppListItem key={item.id} value={item.typeDescription}>
            <p slot="label">{item.typeDescription}</p>
          </WppListItem>
        ))}
      </WppSelect>
      {/* AGENCIES */}
      <WppSelect
        aria-label="Agencies"
        title="Agencies"
        placeholder="Select agencies"
        labelConfig={{
          text: 'Agencies',
        }}
        type="multiple"
        withSearch
        withFolder
        value={agencieSelectValues}
        required
        onWppChange={e => {
          setAgencieSelectValues(e.detail.value)
        }}
      >
        {(agencies as { name: string; id: string }[])?.map(item => (
          <WppListItem key={item.id} value={item.id}>
            <p slot="label">{item.name}</p>
          </WppListItem>
        ))}
      </WppSelect>
      {/* SUB CATEGORIES */}
      <WppSelect
        aria-label="Subcategory"
        title="Subcategory"
        placeholder="Select Subcategory"
        labelConfig={{
          text: 'Subcategory',
        }}
        type="single"
        withSearch
        withFolder
        value={subCategoriesSelectValues}
        required
        onWppChange={e => {
          setSubCategoriesSelectValues(e.detail.value)
        }}
      >
        {subCategories?.map(item => (
          <WppListItem key={item} value={item}>
            <p slot="label">{item}</p>
          </WppListItem>
        ))}
      </WppSelect>
      <WppCheckbox
        required
        checked={isConfidentialValue}
        labelConfig={{ text: 'This file is confidential and must not be shared with others' }}
        className={clsx(useCase && 'mt-2')}
        onWppChange={e => setIsConfidentialValue(e?.detail?.checked)}
      />
      <div className="py-4">
        <div className="mb-2 flex flex-row items-center gap-1">
          <WppTypography type="s-strong" className={style.fileUploadtype}>
            File Upload
          </WppTypography>
        </div>
        {/* File upload or edit */}
        <UseCaseEditAddFile
          wppFile={wppFiles}
          existingFileUrl={existingFileUrl}
          isEditMode={isEditMode}
          handleFileUploadChange={handleFileUploadChange}
          toast={toast}
        />
        {/*<AgencyFileList agencyFiles={agencyFiles} />*/}
      </div>
      <>
        {!useCase && <WppDivider />}
        <div className="flex ">
          <WppButton onClick={onHandleCancel} className="ml-auto mr-4" variant="secondary" disabled={task !== null}>
            Cancel
          </WppButton>
          {useCase && (
            <WppButton onClick={handleEditFile} className="mr-4" disabled={!wppFiles?.length || task !== null}>
              Replace File
            </WppButton>
          )}

          <WppButton
            onClick={!useCase ? onHandleSave : onHandleEdit}
            className="mr-4"
            disabled={disableSaveMemo || task !== null}
          >
            Save
          </WppButton>
        </div>
      </>
    </>
  )
}
