import { TabsChangeEventDetail } from '@wppopen/components-library'
import { WppBreadcrumb, WppIconChevron, WppTab, WppTabs, WppTypography } from '@wppopen/components-library-react'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { useGetAgencies } from 'api/queries/agencies/useGetAgencies'
import { useUseCases } from 'api/queries/use-cases/useUseCases'
import { useToast } from 'hooks/useToast'
import { AgencyDashboardView } from 'pages/agency-dashboard/AgencyDashboardView'

import { UseCasesDashboardView } from './useCases/useCasesDashboardView/UseCasesDashboardView'

enum SettingsTabs {
  AGENCIES = 'agencies',
  USE_CASES = 'use-cases',
}

export const Settings = () => {
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const toast = useToast()
  const { data: dataAgencies = [], isLoading: loadingAgencies, isError: errorAgencies } = useGetAgencies()
  const {
    data: dataUseCases = [],
    isLoading: loadingUseCases,
    isError: errorUseCases,
  } = useUseCases({
    staleTime: 60 * 60 * 100, // 10 minutes
  })
  const totalAgencies = useRef<number | null>(null)
  const totalUseCases = useRef<number | null>(null)
  const viewQuery = searchParams.get('view')

  const { useCasesTotal, agenciesTotal } = useMemo(() => {
    let agenciesTotal = dataAgencies.length
    let useCasesTotal = dataUseCases.length

    if (totalUseCases.current === null || dataUseCases.length !== Number(totalUseCases.current)) {
      totalUseCases.current = dataUseCases.length
      useCasesTotal = dataUseCases.length
    } else {
      useCasesTotal = totalUseCases.current
    }

    if (totalAgencies.current === null || dataAgencies.length !== Number(totalAgencies.current)) {
      totalAgencies.current = dataAgencies.length
      agenciesTotal = dataAgencies.length
    } else {
      agenciesTotal = totalAgencies.current
    }

    return { agenciesTotal, useCasesTotal }
  }, [dataAgencies, dataUseCases])

  if (errorAgencies) {
    toast.showToast({ message: 'Failed to fetch agencies', type: 'error' })
  }

  useEffect(() => {
    if (viewQuery === 'usecases') {
      setCurrentTab(SettingsTabs.USE_CASES)
    } else if (viewQuery === 'grid') {
      setCurrentTab(SettingsTabs.AGENCIES)
    }
  }, [searchParams, viewQuery])

  const [currentTab, setCurrentTab] = useState(
    viewQuery === 'usecases' ? SettingsTabs.USE_CASES : SettingsTabs.AGENCIES,
  )

  const handleTabChange = (event: CustomEvent<TabsChangeEventDetail>) => {
    const value = event.detail.value
    setCurrentTab(event.detail.value as SettingsTabs)
    if (value === 'agencies') {
      setSearchParams({ view: 'grid' })
    }
  }
  return (
    <div className="pb-16">
      <div className="flex mb-4">
        <WppIconChevron direction="left" />
        <WppBreadcrumb
          onWppChange={event => navigate(event.detail.path)}
          items={[{ label: 'Back to Project Dashboard', path: '/' }]}
        />
      </div>

      <div className="flex justify-start mb-4">
        <WppTypography type="xl-heading">Settings</WppTypography>
      </div>

      <WppTabs value={currentTab} onWppChange={handleTabChange}>
        {/* add small padding either side to account size of tab space when count is added */}
        <WppTab className={agenciesTotal === 0 ? 'px-1' : ''} value={SettingsTabs.AGENCIES}>
          Agencies ({agenciesTotal})
        </WppTab>
        {/* add small padding either side to account size of tab space when count is added */}
        <WppTab className={useCasesTotal === 0 ? 'px-1' : ''} value={SettingsTabs.USE_CASES}>
          Use Cases ({useCasesTotal})
        </WppTab>
      </WppTabs>
      {
        {
          agencies: (
            <AgencyDashboardView
              navigate={navigate}
              agencies={dataAgencies}
              isFetching={loadingAgencies && !errorAgencies}
            />
          ),
          'use-cases': (
            <UseCasesDashboardView
              navigate={navigate}
              useCases={dataUseCases}
              isFetching={loadingUseCases && !errorUseCases}
            />
          ),
        }[currentTab]
      }
    </div>
  )
}
