import { ToastState } from '@wppopen/components-library'
import { WppFileUpload, WppIconFile, WppTypography } from '@wppopen/components-library-react'

import { getFileNameFromUrl } from 'utils/projectUtils'

export interface UseCaseEditAddFileProps {
  existingFileUrl: string | null
  isEditMode: boolean
  handleFileUploadChange: (event: CustomEvent) => void
  toast: {
    showToast: (config: ToastState) => void
  }
  wppFile: File[] | null
}

export const UseCaseEditAddFile = ({
  existingFileUrl,
  isEditMode,
  handleFileUploadChange,
  toast,
  wppFile,
}: UseCaseEditAddFileProps) => (
  <>
    <WppFileUpload
      name="description"
      acceptConfig={{ 'application/pdf': ['.pdf'] }}
      onWppChange={handleFileUploadChange}
      locales={{
        label: 'File upload',
        text: 'Drag and drop a file here or click to upload',
        info: (accept, size) => `Accepted file types: ${accept}. Max file size: ${size}MB`,
        sizeError: 'File Size Limit Exceeded',
        formatError: 'Unsupported file extension',
      }}
      onError={() => {
        toast.showToast({
          message: 'Your file upload was not successful',
          type: 'error',
        })
      }}
      format="arrayBuffer"
      multiple={false}
      size={500}
    />
    {isEditMode && existingFileUrl && (wppFile?.length === 0 || wppFile === null) && (
      <div className="flex gap-2 bg-[#F4F5F7] rounded-6 p-3">
        <WppIconFile />
        <WppTypography type="s-body" className="underline text-[#343A3F]">
          {/*replace with file name from api after it's updated*/}
          {`${getFileNameFromUrl(existingFileUrl)}.pdf`}
        </WppTypography>
        <WppTypography type="s-body" className="text-[#697077]">
          {/* file size should be from the api */}
        </WppTypography>
      </div>
    )}
  </>
)
