import { WppIconFile, WppSpinner, WppTag, WppTypography } from '@wppopen/components-library-react'

import { useUseCase } from 'api/queries/use-cases/useUseCase'
import { getFileNameFromUrl } from 'utils/projectUtils'

export const UseCasesDetails = ({ useCaseId }: { useCaseId: string }) => {
  const { data: useCase, isFetching } = useUseCase({ params: { id: useCaseId || '' }, refetchOnWindowFocus: true })

  const { description, summary, agencyIds, markets, client, pitchTypes, subCategory, isConfidential, fileUrl } = useCase
  if (isFetching) return <WppSpinner size="s" />
  return (
    <>
      {description && (
        <div>
          <WppTypography type="s-body">{description}</WppTypography>
        </div>
      )}
      <div>
        <WppTypography className="text-grey mb-2" type="s-strong">
          LLM Generated Summary:
        </WppTypography>
        <WppTypography type="s-body">{summary}</WppTypography>
      </div>
      <div>
        {isConfidential ? (
          <WppTag label="Confidential file" variant="warning" />
        ) : (
          <WppTag label="Non-Confidential file" variant="warning" />
        )}
      </div>
      {[
        { label: 'Agency', value: agencyIds },
        { label: 'Market', value: markets.join(', ') },
        { label: 'Client', value: client },
        { label: 'Pitch Types', value: pitchTypes.join(', ') },
        { label: 'Subcategory', value: subCategory },
      ].map((item, idx) => (
        <div className="flex gap-2" key={`${item.label}-${idx}`}>
          <WppTypography className="text-grey" type="s-strong">
            {item.label}:
          </WppTypography>
          <WppTypography type="s-body">{item.value}</WppTypography>
        </div>
      ))}
      <WppTypography type="xl-heading">Uploaded Files</WppTypography>
      <div className="flex gap-2 bg-[#F4F5F7] rounded-6 p-3">
        <WppIconFile />
        <WppTypography type="s-body" className="underline text-[#343A3F]">
          {/*replace with file name from api after it's updated*/}
          {`${getFileNameFromUrl(fileUrl)}.pdf`}
        </WppTypography>
        <WppTypography type="s-body" className="text-[#697077]">
          {/* file size should be from the api */}
        </WppTypography>
      </div>
    </>
  )
}
