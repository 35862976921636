import { UseCase } from 'types/use-cases/useCase'

export interface UseCasesState extends Partial<UseCase> {
  isEditmode: boolean
  currentTab: Tab
  title?: string
  form: {
    isSaveAndCancelDisabled: boolean
    titleValue?: string
    isDirty: boolean
    isPristine: boolean
  }
}

export enum Tab {
  DETAILS = 'DETAILS',
}
