import {
  WppBreadcrumb,
  WppButton,
  WppIconChevron,
  WppIconDownload,
  WppIconTrash,
  WppModal,
  WppSpinner,
  WppTypography,
} from '@wppopen/components-library-react'
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { useUseCase } from 'api/queries/use-cases/useUseCase'
import { downloadFile } from 'utils/projectUtils'

import { UseCaseDeleteModal } from './UseCaseDeleteModal'
import { UseCaseDetailsEditView } from './UseCaseDetailsEditView'

export const UseCasesContainer = () => {
  const navigate = useNavigate()
  const { useCaseId } = useParams()
  const [showDeleteButton, setShowDeleteButton] = useState(false)
  const { data: useCase, isLoading } = useUseCase({ params: { id: useCaseId || '' } })
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)

  return (
    <div>
      <div className="flex mb-4 gap-2">
        <WppIconChevron direction="left" />
        <WppBreadcrumb
          onWppChange={event => navigate(event.detail.path)}
          items={[
            { label: 'Back to Project Dashboard', path: '/' },
            { label: 'Settings', path: '/rfi-helper-tool/settings?view=usecases' },
            { label: `${useCase?.title || 'Use case'}`, path: `/settings/use-cases/${useCase?.id}` },
          ]}
        />
      </div>

      {isLoading ? (
        <div className="flex justify-center items-center h-full">
          <WppSpinner size="l" />
        </div>
      ) : (
        <>
          <div className="flex justify-between items-center mb-4">
            <WppTypography type="xl-heading">{useCase.title}</WppTypography>
            {showDeleteButton ? (
              <WppButton className="ml-auto" variant="destructive-secondary" onClick={() => setIsDeleteModalOpen(true)}>
                <div className="flex items-center gap-2">
                  <WppIconTrash color="var(--button-destructive-secondary-text-color)" />
                  <span>Delete Use Case</span>
                </div>
              </WppButton>
            ) : (
              <WppIconDownload
                color="var(--color-font-blue-primary)"
                onClick={e => downloadFile(e, useCase.fileUrl)}
                className="cursor-pointer"
              />
            )}
          </div>
          <UseCaseDetailsEditView useCase={useCase} setShowDeleteButton={setShowDeleteButton} />
          <WppModal open={isDeleteModalOpen} onWppModalClose={() => setIsDeleteModalOpen(false)} size="s">
            <UseCaseDeleteModal
              useCaseId={useCaseId ?? ''}
              name={useCase?.title}
              handleModalClose={() => {
                setIsDeleteModalOpen(false)
              }}
              handleDeleteSuccess={() => {
                setIsDeleteModalOpen(false)
                navigate('/rfi-helper-tool/settings?view=usecases')
              }}
            />
          </WppModal>
        </>
      )}
    </div>
  )
}
