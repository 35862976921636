import { useOs } from '@wppopen/react'
import { Navigate, Outlet } from 'react-router-dom'

import { agencyRoles } from 'constants/agencyRoles'

export const SettingsRoutes = () => {
  const {
    osContext: { permissions },
  } = useOs()

  const allpermissions = permissions.map(permission => permission.permissions)
  const hasPermission = allpermissions.flat().includes(agencyRoles.RFI_AGENCY_MANAGE)

  return hasPermission ? <Outlet /> : <Navigate to="/" />
}
