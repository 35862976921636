import { InputChangeEventDetail } from '@wppopen/components-library'
import { WppInputCustomEvent } from '@wppopen/components-library/dist/types/components'
import {
  WppActionButton,
  WppButton,
  WppFilterButton,
  WppIconAdd,
  WppIconButton,
  WppIconDataViewCards,
  WppIconDataViewList,
  WppIconReset,
  WppInput,
  WppListItem,
  WppMenuContext,
  WppModal,
  WppSideModal,
  WppSortButton,
} from '@wppopen/components-library-react'
import clsx from 'clsx'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { EditAddAgency } from 'pages/agency-dashboard/EditAddAgency'
import { KnowledgeBaseMergeSelection } from 'pages/agency-knowledge-base/KnowledgeBaseMergeSelection'

import style from './agencyFilterGroup.module.scss'
import { AgencyFilterGroupProps } from './types'

export const AgencyFilterGroup = ({
  handleToggle,
  toggleView,
  handleSearchChange,
  filters = '',
  filterCount = 0,
  handleResetFilters,
  handleApplyFilters,
  handleSortFilter,
  handleCancelFilters,
  disabled,
  sortingState,
  hideSorts = false,
  isAgenciesAdmin = false,
  searchValue,
}: AgencyFilterGroupProps) => {
  const navigate = useNavigate()
  const onSearchChange = (e: WppInputCustomEvent<InputChangeEventDetail>) => handleSearchChange(e?.detail?.value || '')
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false)
  const [modalContent, setModalContent] = useState<'filters' | 'add' | 'knowledgeBase' | ''>('')
  const [knowledgeBaseModalProps, setKnowledgeBaseModalProps] = useState<{
    agencyId: string
    fileName: string | null | undefined
    fileStatusTaskId: string
  } | null>(null)

  const handleOpenModal = (modal: 'filters' | 'add' | 'knowledgeBase') => {
    setIsModalOpen(true)
    setModalContent(modal)
  }
  const handleCloseModal = () => setIsModalOpen(false)
  const handleCancel = () => {
    handleCancelFilters()
    handleCloseModal()
  }
  const handleReset = () => {
    handleResetFilters()
  }
  const handleApply = () => {
    handleApplyFilters()
    handleCloseModal()
  }

  return (
    <div className="flex mb-4">
      <div className="flex gap-4 w-1/2">
        <WppInput
          disabled={disabled}
          size="s"
          placeholder="Search"
          aria-label="Search agencies"
          id="search-input"
          onWppChange={onSearchChange}
          value={searchValue}
        />
        <WppFilterButton
          name="Filters"
          onClick={() => handleOpenModal('filters')}
          counter={filterCount}
          disabled={disabled}
        >
          Filters
        </WppFilterButton>
        <WppSideModal
          size={modalContent === 'knowledgeBase' ? '2xl' : 'm'}
          open={isModalOpen}
          onWppSideModalClose={() => {
            if (modalContent === 'knowledgeBase') {
              setIsConfirmModalOpen(true)
            } else {
              handleCloseModal()
            }
          }}
        >
          {filters && modalContent === 'filters' && (
            <>
              <h3 slot="header">Filter</h3>
              <div slot="body" className="flex flex-col gap-6">
                {filters}
              </div>
              <div slot="actions" className="flex justify-between w-full">
                {filterCount > 0 && (
                  <WppActionButton variant="primary" onClick={handleReset}>
                    Reset <WppIconReset slot="icon-start" />
                  </WppActionButton>
                )}

                <WppButton variant="secondary" size="s" onClick={handleCancel} className="ml-auto mr-3">
                  Cancel
                </WppButton>
                <WppButton variant="primary" size="s" onClick={handleApply}>
                  Apply
                </WppButton>
              </div>
            </>
          )}
          {modalContent === 'add' && (
            <>
              <h3 slot="header">Add an agency</h3>
              <div slot="body" className="flex flex-col gap-6">
                <EditAddAgency
                  handleSave={async ({ id, fileName, fileStatusTaskId }) => {
                    if (fileStatusTaskId) {
                      handleOpenModal('knowledgeBase')
                      setKnowledgeBaseModalProps({ agencyId: id, fileName, fileStatusTaskId })
                    } else {
                      navigate(`/rfi-helper-tool/settings/agency-dashboard/${id}`)
                      handleCloseModal()
                    }
                  }}
                  handleCancel={handleCloseModal}
                />
              </div>
            </>
          )}
          {modalContent === 'knowledgeBase' && knowledgeBaseModalProps && (
            <>
              <h3 slot="header" className="border-b">
                Uploaded File Preview and Import Selection
              </h3>
              <div slot="body" className="flex flex-col gap-6">
                <KnowledgeBaseMergeSelection
                  agencyId={knowledgeBaseModalProps.agencyId}
                  fileName={knowledgeBaseModalProps.fileName}
                  fileStatusTaskId={knowledgeBaseModalProps.fileStatusTaskId}
                  handleSave={() => {
                    handleCloseModal()
                    navigate(`/rfi-helper-tool/settings/agency-dashboard/${knowledgeBaseModalProps.agencyId}`)
                  }}
                  handleCancel={() => {
                    setIsConfirmModalOpen(true)
                  }}
                />
              </div>
            </>
          )}
        </WppSideModal>
        <WppModal size="s" open={isConfirmModalOpen}>
          <h3 slot="header">Are you sure you want to cancel?</h3>
          <p slot="body">You have unsaved changes. Are you sure you want to continue?</p>

          <div slot="actions" className="flex flex-row justify-end gap-4">
            <WppButton variant="secondary" onClick={() => setIsConfirmModalOpen(false)} size="s">
              Go back
            </WppButton>
            <WppButton
              size="s"
              variant="destructive"
              onClick={() => {
                handleCloseModal()
                if (knowledgeBaseModalProps?.agencyId) {
                  navigate(`/rfi-helper-tool/settings/agency-dashboard/${knowledgeBaseModalProps.agencyId}`)
                }
              }}
            >
              Confirm
            </WppButton>
          </div>
        </WppModal>
      </div>

      <div className="flex  ml-auto">
        {!hideSorts && (
          <WppMenuContext className="mr-4">
            <WppSortButton slot="trigger-element" name="Sorts" disabled={disabled}>
              Sort
            </WppSortButton>
            <div>
              <WppListItem
                onWppChangeListItem={() =>
                  handleSortFilter('name', sortingState['name'] === undefined || sortingState['name'] === 'DESC')
                }
              >
                <p slot="label">
                  Agency Name{' '}
                  {sortingState['name'] === undefined ? '(A-Z)' : sortingState['name'] === 'ASC' ? '(Z-A)' : '(A-Z)'}
                </p>
              </WppListItem>
              <WppListItem
                onWppChangeListItem={() =>
                  handleSortFilter(
                    'numberOfCategories',
                    sortingState['numberOfCategories'] === undefined || sortingState['numberOfCategories'] === 'DESC',
                  )
                }
              >
                <p slot="label">Knowledge Base </p>
              </WppListItem>
              <WppListItem
                onWppChangeListItem={() =>
                  handleSortFilter(
                    'createdAt',
                    sortingState['createdAt'] === undefined || sortingState['createdAt'] === 'DESC',
                  )
                }
              >
                <p slot="label">
                  Date Created
                  {sortingState['createdAt'] === undefined
                    ? ' (Old-New)'
                    : sortingState['createdAt'] === 'ASC'
                      ? ' (New-Old)'
                      : ' (Old-New)'}
                </p>
              </WppListItem>
              <WppListItem
                onWppChangeListItem={() =>
                  handleSortFilter(
                    'createdBy',
                    sortingState['createdBy'] === undefined || sortingState['createdBy'] === 'DESC',
                  )
                }
              >
                <p slot="label">
                  Added By{' '}
                  {sortingState['createdBy'] === undefined
                    ? '(A-Z)'
                    : sortingState['createdBy'] === 'ASC'
                      ? '(Z-A)'
                      : '(A-Z)'}
                </p>
              </WppListItem>
            </div>
          </WppMenuContext>
        )}

        <WppIconButton
          size="s"
          className={clsx(style.lButton, { [style.selected]: toggleView === 'grid' })}
          onClick={() => handleToggle('grid')}
          disabled={disabled}
        >
          <WppIconDataViewCards />
        </WppIconButton>
        <WppIconButton
          className={clsx(style.rButton, { [style.selected]: toggleView === 'list' })}
          size="s"
          onClick={() => handleToggle('list')}
          disabled={disabled}
        >
          <WppIconDataViewList />
        </WppIconButton>

        {isAgenciesAdmin ? (
          <WppButton
            className="ml-4"
            variant="primary"
            onClick={() => handleOpenModal('add')}
            size="s"
            disabled={disabled}
          >
            <WppIconAdd slot="icon-start" />
            New Agency
          </WppButton>
        ) : null}
      </div>
    </div>
  )
}
