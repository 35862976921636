import { WppActionButton, WppIconDownload, WppSpinner } from '@wppopen/components-library-react'
import axios from 'axios'
import { saveAs } from 'file-saver'
import JSZip from 'jszip'
import { useState } from 'react'

import { useToast } from 'hooks/useToast'

const DownloadZip = ({ urls, disabled }: { urls: string[]; disabled: boolean }) => {
  const [downloading, setDownloading] = useState(false)
  const toast = useToast()
  const downloadAndZipFiles = async () => {
    setDownloading(true)
    const zip = new JSZip()
    const pdfFolder = zip.folder('pdfs')

    const fetchFile = async (url: string) => {
      const response = await axios.get(url, {
        responseType: 'blob',
      })
      if (response.status !== 200) {
        toast.showToast({
          message: 'Something went wrong while downloading the files. Please try again.',
          type: 'error',
        })
        setDownloading(false)
        return
      }
      return response.data
    }

    const promises = urls.map(async (url, index) => {
      const fileData = await fetchFile(url)
      const fileName = `file${index + 1}.pdf`
      pdfFolder && pdfFolder.file(fileName, fileData)
    })

    await Promise.all(promises)

    const content = await zip.generateAsync({ type: 'blob' })
    setDownloading(false)
    saveAs(content, 'use-cases.zip')
  }

  return (
    <WppActionButton disabled={disabled || downloading} variant="secondary" onClick={downloadAndZipFiles}>
      {downloading ? <WppSpinner size="s" /> : <WppIconDownload slot="icon-start" />}
    </WppActionButton>
  )
}

export default DownloadZip
