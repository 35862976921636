import { useEffect, useState } from 'react'

import { sortType } from 'components/ProjectsFilterGroup/types'
import { ProjectDetails } from 'types/rfis/rfi'

export type ProjectSortFilterReturn = [
  { data: ProjectDetails[]; activeSortType: sortType; sortAsc: boolean },
  (projects: ProjectDetails[], type: sortType, preserveDirection: boolean) => ProjectDetails[],
]

export const useProjectSortFilters = (
  type: sortType = 'dateFromTo',
  projects?: ProjectDetails[],
  isAsc: boolean = true,
): ProjectSortFilterReturn => {
  const [sortAsc, setSortAsc] = useState<boolean>(isAsc)
  const [activeSortType, setActiveSortType] = useState<sortType>(type)
  const [sortedData, setSortedData] = useState<ProjectDetails[]>([])

  const handleSortName = (projects: ProjectDetails[], preserveDirection: boolean) => {
    const sortedData = projects.toSorted((a, b) => {
      const operator = (!preserveDirection ? !sortAsc : sortAsc) ? -1 : 1
      return operator * a.projectName.localeCompare(b.projectName)
    })
    setSortedData(sortedData)
    !preserveDirection && setSortAsc(!sortAsc)
    setActiveSortType('name')
    return sortedData
  }

  const handleSortDateFromTo = (projects: ProjectDetails[], preserveDirection: boolean) => {
    const sortedData = projects.toSorted((a, b) => {
      const operator = (!preserveDirection ? !sortAsc : sortAsc) ? 1 : -1
      return operator * b.createdAt.localeCompare(a.createdAt)
    })
    setSortedData(sortedData)
    !preserveDirection && setSortAsc(!sortAsc)
    setActiveSortType('dateFromTo')
    return sortedData
  }

  const handleSortFilter = (projects: ProjectDetails[], type: sortType, preserveSortDirection = false) => {
    switch (type) {
      case 'name': {
        return handleSortName(projects, preserveSortDirection)
      }
      case 'dateFromTo': {
        return handleSortDateFromTo(projects, preserveSortDirection)
      }
      default: {
        return []
      }
    }
  }

  useEffect(() => {
    // sort on initial render
    if (type && projects) {
      handleSortFilter(projects, type)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // return the handlers and the active sort type
  return [{ data: sortedData, activeSortType, sortAsc }, handleSortFilter]
}
