import { WppActionButton, WppAvatar, WppIconMore, WppListItem, WppMenuContext } from '@wppopen/components-library-react'
import { ColDef } from 'ag-grid-community'

import CellWithTooltip from 'components/Grid/CellWithTooltip'
import { ApiSortTypes } from 'hooks/useApiSortFilters'
import { Agency } from 'types/agencies/agencies'
import { getAgenciesNamesByIds } from 'utils/projectUtils'

import { TableDataItem } from './UseCasesAgGrid'
import { sortType } from '../useCasesFilterGroup/types'

export const columnDefenitions = (
  agencies: Agency[],
  handleEditClick: (id: string) => void,
  handleDeleteClick: (id: string, name: string) => void,
  sortingState: ApiSortTypes<sortType>,
): ColDef[] => [
  {
    field: 'title',
    sortable: true,
    ...(sortingState['title'] && { sort: sortingState['title'] === 'ASC' ? 'asc' : 'desc' }),
    headerName: 'Name',
    flex: 1.5,
    rowDrag: false,
    cellRenderer: ({ data }: { data: TableDataItem }) => <CellWithTooltip value={data.title} />,
    comparator: () => 0,
  },
  /*{
    field: 'isConfidential',
    sortable: true,
    ...(sortingState['isConfidential'] && { sort: sortingState['isConfidential'] === 'ASC' ? 'asc' : 'desc' }),
    headerName: 'Confidentiality',
    rowDrag: false,
    
    cellRenderer: ({ data }: { data: TableDataItem }) =>
      `${data.isConfidential ? 'Confidential file' : 'Not confidential file'}`,
    flex: 1.5,
    comparator: () => 0,
  },*/
  {
    field: 'agency',
    sortable: false,
    headerName: 'Agency',
    flex: 1.5,
    rowDrag: false,

    cellRenderer: ({ data }: { data: TableDataItem }) => (
      <CellWithTooltip value={getAgenciesNamesByIds(agencies, data.agencyIds)} />
    ),
    comparator: () => 0,
  },
  {
    field: 'market',
    sortable: false,
    headerName: 'Market',
    flex: 1.5,
    rowDrag: false,

    cellRenderer: ({ data }: { data: TableDataItem }) => <CellWithTooltip value={data.markets.join(', ')} />,
  },
  {
    field: 'client',
    sortable: true,
    ...(sortingState['client'] && { sort: sortingState['client'] === 'ASC' ? 'asc' : 'desc' }),
    headerName: 'Client',
    flex: 1.5,
    rowDrag: false,

    cellRenderer: ({ data }: { data: TableDataItem }) => <CellWithTooltip value={data.client} />,
    comparator: () => 0,
  },
  {
    field: 'pitchType',
    sortable: false,
    headerName: 'Pitch Type',
    flex: 1.5,
    rowDrag: false,

    cellRenderer: ({ data }: { data: TableDataItem }) => <CellWithTooltip value={data.pitchTypes.join(', ')} />,
    comparator: () => 0,
  } /*
  {
    field: 'subCategory',
    sortable: true,
    ...(sortingState['subCategory'] && { sort: sortingState['subCategory'] === 'ASC' ? 'asc' : 'desc' }),
    headerName: 'Subcategory',
    flex: 1.5,
    rowDrag: false,
    
    cellRenderer: ({ data }: { data: TableDataItem }) => `${data.subCategory}`,
    comparator: () => 0,
  },*/,
  {
    field: 'createdBy',
    headerName: 'Added By',
    sortable: true,
    ...(sortingState['createdBy'] && { sort: sortingState['createdBy'] === 'ASC' ? 'asc' : 'desc' }),
    comparator: () => 0,
    cellRenderer: ({ data }: { data: TableDataItem }) => (
      <div className="flex flex-row justify-start items-center gap-4 truncate">
        {data.createdBy && (
          <WppAvatar
            size="m"
            className="max-w-8 max-h-8 flex-shrink-0 flex-grow-0 flex-auto"
            withTooltip
            name={data.createdBy || ''}
            src={data.user?.img || ''}
          />
        )}
        <i>{data?.createdBy || ''}</i>
      </div>
    ),
    flex: 1,
  },
  {
    headerName: '',
    field: 'Actions',
    width: 100,
    cellRenderer: ({ data }: { data: TableDataItem }) => (
      <div className="flex flex-col justify-center h-full">
        <WppMenuContext
          data-context-menu
          onClick={e => {
            e.stopPropagation()
          }}
        >
          <WppActionButton
            slot="trigger-element"
            data-context-menu
            onClick={e => {
              e.stopPropagation()
            }}
          >
            <WppIconMore
              direction="horizontal"
              data-context-menu
              onClick={e => {
                e.stopPropagation()
              }}
            />
          </WppActionButton>
          <div>
            <WppListItem onWppChangeListItem={() => handleEditClick(data.id)}>
              <p slot="label">View</p>
            </WppListItem>
            <WppListItem onWppChangeListItem={() => handleDeleteClick(data.id, data.title)}>
              <p slot="label">Delete</p>
            </WppListItem>
          </div>
        </WppMenuContext>
      </div>
    ),
  },
]
