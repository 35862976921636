import { TabsChangeEventDetail } from '@wppopen/components-library'
import { WppTabsCustomEvent } from '@wppopen/components-library/dist/types/components'
import { WppButton, WppIconEdit, WppTab, WppTabs, WppTypography } from '@wppopen/components-library-react'
import clsx from 'clsx'
import { useEffect, useState } from 'react'

import { UseCase } from 'types/use-cases/useCase'

import { Tab } from './types'
import { UseCasesDetails } from './UseCasesDetails'
import style from './useCasesDetailsEditVeiw.module.scss'
import { UseCasesEditAdd } from './useCasesEditAdd/UseCasesEditAdd'

export const UseCaseDetailsEditView = ({
  useCase,
  setShowDeleteButton,
}: {
  useCase: UseCase
  setShowDeleteButton: (show: boolean) => void
}) => {
  const [isEditmode, setIsEditmode] = useState(false)
  const [currentTab, setCurrentTab] = useState<Tab>(Tab.DETAILS)
  const title = useCase?.title
  const handleTabChange = (event: WppTabsCustomEvent<TabsChangeEventDetail>) => setCurrentTab(event.detail.value as Tab)

  const handleEditButtonClick = () => setIsEditmode(prev => !prev)

  const handleSave = () => setIsEditmode(false)
  const handleCancel = () => setIsEditmode(false)

  useEffect(() => {
    setShowDeleteButton(isEditmode)
  }, [isEditmode, setShowDeleteButton])

  return (
    <div data-is-edit={isEditmode} className={style.UseCaseDetailsEditView}>
      <WppTabs value={currentTab} onWppChange={handleTabChange}>
        <WppTab value={Tab.DETAILS}>Details</WppTab>
      </WppTabs>
      {currentTab === Tab.DETAILS && (
        <div className={clsx('flex flex-col gap-4', style.tab)}>
          <div className="flex justify-between">
            <WppTypography className={style.title} type="xl-heading">
              {title}
            </WppTypography>
            <WppButton className={style.editButton} variant="secondary" onClick={handleEditButtonClick}>
              <div className="flex items-center gap-2">
                <WppIconEdit color="var(--color-font-blue-primary)" size="s" />
                <WppTypography type="s-strong" className="text-primary">
                  Edit
                </WppTypography>
              </div>
            </WppButton>
          </div>
          {isEditmode ? (
            <UseCasesEditAdd useCase={useCase} handleCancel={handleCancel} handleSave={handleSave} showLlmSummary />
          ) : (
            <UseCasesDetails useCaseId={useCase?.id} />
          )}
        </div>
      )}
    </div>
  )
}
