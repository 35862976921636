import { WppButton, WppCheckbox, WppDivider, WppSpinner } from '@wppopen/components-library-react'
import { useEffect, useMemo, useState } from 'react'

import { useMergeAgencyCategories } from 'api/mutations/agencyCategories/useMergeAgencyCategories'
import { useGetAgencyCategoriesMergePreview } from 'api/queries/agency-categories/useGetAgencyCategoriesMergePreview'
import { useTasksStatus } from 'api/queries/task-status/useTasksStatus'
import { useToast } from 'hooks/useToast'

import { AgencyCategoryPreview } from '@/types/agency-categories/agency-categories'

interface Props {
  agencyId: string
  fileName: string | null | undefined
  fileStatusTaskId: string
  handleSave: () => void
  handleCancel: () => void
}

export const KnowledgeBaseMergeSelection = ({
  agencyId,
  fileName,
  fileStatusTaskId,
  handleSave,
  handleCancel,
}: Props) => {
  const [fileId, setFileId] = useState<string | null>(null)
  const { data: taskStatus } = useTasksStatus({
    params: { taskId: fileStatusTaskId },
    enabled: !!fileStatusTaskId && !fileId,
    refetchInterval: 5000,
  })

  const { data: knowledgeBase } = useGetAgencyCategoriesMergePreview({
    params: { agencyId, fileId: fileId! },
    enabled: !!fileId,
  })
  const incomingContent = useMemo(() => knowledgeBase?.incomingContent || [], [knowledgeBase])

  const [isSaving, setIsSaving] = useState(false)
  const [selectedContent, setSelectedContent] = useState<string[]>([])

  const incomingParentCategories = useMemo(() => {
    return incomingContent.filter(item => !item.parentCategoryId)
  }, [incomingContent])
  const selectedParentCategoriesCount = useMemo(() => {
    return incomingParentCategories.filter(item => selectedContent.includes(item.id)).length
  }, [selectedContent, incomingParentCategories])

  const toast = useToast()
  const { mutateAsync: mergeCategories } = useMergeAgencyCategories({
    onError: error => {
      toast.showToast({
        message: error.message,
        type: 'error',
      })
      handleCancel()
    },
  })

  useEffect(() => {
    if (taskStatus) {
      if (taskStatus.error) {
        toast.showToast({
          message: 'Your file upload was not successful',
          type: 'error',
        })
        handleCancel()
      }
      if (taskStatus?.completed) {
        setFileId(taskStatus?.resultObjectId)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskStatus])

  const mapToCategoryIds = (selectedContent: string[]) => {
    const mappedSelectedContent = new Set(selectedContent)
    for (const itemId of selectedContent) {
      const category = incomingContent.find(i => i.id === itemId)
      if (!category || !category.parentCategoryId) continue
      mappedSelectedContent.add(category.parentCategoryId)
    }
    return Array.from(mappedSelectedContent.values())
  }

  const onHandleSave = async () => {
    setIsSaving(true)

    try {
      const res = await mergeCategories({ categoryIds: mapToCategoryIds(selectedContent) })
      console.log('res', res)

      toast.showToast({
        message: "You've successfully added file.",
        type: 'success',
      })
    } catch {
      toast.showToast({
        message: 'Your file upload was not successful',
        type: 'error',
      })
    }

    setIsSaving(false)
    handleSave()
  }

  const onHandleChecked = (item: AgencyCategoryPreview, isChecked: boolean) => {
    let currentSelectedContent = new Set(selectedContent)
    if (isChecked) {
      currentSelectedContent.delete(item.id)
      if (!item.parentCategoryId) {
        const subCategories = incomingContent.filter(i => i.parentCategoryId === item.id)
        for (const subCategory of subCategories) {
          currentSelectedContent.delete(subCategory.id)
        }
      } else {
        currentSelectedContent.delete(item.parentCategoryId)
      }
    } else {
      currentSelectedContent.add(item.id)
      if (!item.parentCategoryId) {
        const subCategories = incomingContent.filter(i => i.parentCategoryId === item.id)
        for (const subCategory of subCategories) {
          currentSelectedContent.add(subCategory.id)
        }
      } else {
        const parentSubCategories = incomingContent.filter(i => i.parentCategoryId === item.parentCategoryId)
        const subCategoriesSelected = parentSubCategories.filter(
          subCategory => !selectedContent.includes(subCategory.id),
        )
        if (subCategoriesSelected.length === 1 && subCategoriesSelected[0].id === item.id) {
          currentSelectedContent.add(item.parentCategoryId)
        }
      }
    }
    setSelectedContent(Array.from(currentSelectedContent.values()))
  }

  const onHandleCheckAll = () => {
    setSelectedContent(incomingContent.map(item => item.id))
  }

  return (
    <>
      <WppDivider />

      <div className="max-h-[calc(100vh-216px)] h-[calc(100vh-216px)] overflow-y-auto flex flex-col gap-y-6 px-8">
        <h4>{fileName ?? 'Untitled file'}</h4>
        <p>
          Preview what is being imported and make a selection to confirm the import. The content that is not selected
          will not be imported and therefore not added in the knowledge base.
        </p>

        <WppDivider />

        {(!taskStatus || !taskStatus.completed) && (
          <>
            <div className="justify-center items-center w-full flex flex-col h-full">
              <WppSpinner size="l" />
              <p>{taskStatus?.status}</p>
            </div>
          </>
        )}

        {taskStatus?.completed && (
          <>
            <div className="justify-between items-center w-full flex">
              <span className="uppercase text-10 font-bold">Select Content</span>
              <WppButton variant="secondary" size="s" disabled={isSaving} onClick={onHandleCheckAll}>
                Select All
              </WppButton>
            </div>

            {incomingParentCategories.map(item => {
              const subCategories = incomingContent.filter(i => i.parentCategoryId === item.id)

              return (
                <div key={item.id} className="flex flex-col gap-y-6">
                  <div className="flex justify-between items-center w-full">
                    <h5 className="font-semibold text-xl">{item.name}</h5>
                    <WppCheckbox
                      onWppChange={e => onHandleChecked(item, e.target.checked)}
                      checked={selectedContent.includes(item.id)}
                      controlled
                    />
                  </div>

                  <div className="flex flex-col gap-y-6">
                    {subCategories.map(subCategory => {
                      return (
                        <div key={subCategory.id} className="flex flex-col gap-y-6">
                          <div className="flex justify-between items-center w-full">
                            <h6 className="font-semibold text-lg">{subCategory.name}</h6>
                            <WppCheckbox
                              onWppChange={e => onHandleChecked(subCategory, e.target.checked)}
                              checked={selectedContent.includes(subCategory.id)}
                              controlled
                            />
                          </div>

                          {subCategory.instruction && <p>{subCategory.instruction}</p>}
                        </div>
                      )
                    })}
                  </div>
                </div>
              )
            })}
          </>
        )}
      </div>

      <WppDivider />

      <div className="flex justify-between">
        <div className="font-semibold">Selected: {selectedContent.length - selectedParentCategoriesCount}</div>
        <div className="flex gap-4">
          <WppButton onClick={handleCancel} variant="secondary" disabled={isSaving}>
            Cancel
          </WppButton>
          <WppButton
            onClick={() => {
              onHandleSave()
            }}
            disabled={isSaving || selectedContent.length === 0}
          >
            Confirm Selection
          </WppButton>
        </div>
      </div>

      {/* {taskStatus ? <LoaderProgressWithDescription fullScreen taskStatus={taskStatus} /> : null} */}
    </>
  )
}
