import { PaginationChangeEventDetail } from '@wppopen/components-library'
import { WppPagination } from '@wppopen/components-library-react'
import { useEffect, useState } from 'react'

import FileCmp from 'pages/relevant-notes/FileCmp'
import { UseCase } from 'types/use-cases/useCase'

export const paginated = (page: number, currPage: number, pageSize: number, data: UseCase[]) =>
  data.slice(page * pageSize, currPage * pageSize)

export interface Props {
  useCases: UseCase[]
  handleEditClick: (id: string) => void
  handleDeleteClick: (id: string) => void
}

export const UseCasesCards = ({ useCases, handleEditClick, handleDeleteClick }: Props) => {
  const [currPage, setCurrPage] = useState<number>(1)
  const [paginationPageSize, setPaginationPageSize] = useState<number>(10)

  const handlePaginationChange = async (event: CustomEvent<PaginationChangeEventDetail>) => {
    const { itemsPerPage, page } = event.detail
    if (paginationPageSize !== itemsPerPage) {
      setPaginationPageSize(itemsPerPage)
    }
    if (currPage !== page) {
      setCurrPage(page)
    }
  }

  useEffect(() => {
    /* make sure if new search filtered data lower than 1 page - default to first results page */
    if (Math.floor(useCases.length / paginationPageSize) < paginationPageSize) {
      setCurrPage(1)
    }
  }, [useCases.length, paginationPageSize])

  return (
    <>
      <div className="flex flex-row flex-wrap gap-y-4 gap-x-[2%]">
        {paginated(currPage - 1, currPage, paginationPageSize, useCases)?.map((useCase: UseCase, idx: number) => (
          <FileCmp
            key={idx}
            useCase={useCase}
            onClick={() => handleEditClick(useCase.id)}
            handleDeleteClick={handleDeleteClick}
            isAdmin={true}
          />
        ))}
      </div>
      {useCases.length > paginationPageSize && (
        <WppPagination
          count={useCases.length}
          itemsPerPage={[10, 20, 50, 100]}
          activePageNumber={currPage}
          pageSelectThreshold={9}
          data-testid="pagination-list"
          selectedItemPerPage={paginationPageSize}
          onWppChange={handlePaginationChange}
          className="mt-6"
        />
      )}
    </>
  )
}
