import { useOs } from '@wppopen/react'
import { useEffect, PropsWithChildren, useState } from 'react'

import { rfiHelperApi, rfiHelperFormApi, masterDataApi, userDataApi, facadeApi } from 'api'

const apiInstances = [rfiHelperApi, rfiHelperFormApi, masterDataApi, userDataApi, facadeApi]

export const ApiProvider = ({ children }: PropsWithChildren<{}>) => {
  const [isReady, setIsReady] = useState(false)
  const { osApi, osContext } = useOs()

  const tenantId = osContext.tenant.id

  useEffect(() => {
    const interceptors = apiInstances.map(instance =>
      instance.client.interceptors.request.use(
        instanceConfig => {
          const bearer = osApi.getAccessToken()
          if (bearer) {
            instanceConfig!.headers!.Authorization = `Bearer ${bearer}`
          }

          if (tenantId) {
            instanceConfig!.headers!['X-Tenant-Id'] = tenantId
          }

          return instanceConfig
        },
        error => Promise.reject(error),
      ),
    )

    setIsReady(true)

    return () => {
      interceptors.forEach((interceptor, index) => {
        apiInstances[index].client.interceptors.request.eject(interceptor)
      })
    }
    // eslint-disable-next-line
  }, [osApi.getAccessToken])

  return <>{isReady && children}</>
}
